<template>
  <div>Logging out</div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  async created() {
    await firebase.auth().signOut();
    this.$router.push({ path: "/login" });
    this.$store.dispatch("auth/clearAuth");
  },
};
</script>
